export function useRecaptcha() {
  const state = reactive({
    isScriptStartLoad: false,
    isReady: false,
  })

  const config = useRuntimeConfig()

  const { load: loadStyle } = useStyleTag('.grecaptcha-badge { visibility: hidden; }', {
    manual: true,
  })
  const { load: loadScript } = useScriptTag(
    `https://www.google.com/recaptcha/enterprise.js?render=${config.public.recaptchaSiteKey}`,
    () => {
      window.grecaptcha.enterprise.ready(function () {
        state.isReady = true
      })
    },
    {
      manual: true,
    }
  )

  const init = async () => {
    await Promise.all([loadScript(true), loadStyle()])
  }

  const execute = async (action: string, callback?: (token: string) => string) => {
    if (!state.isReady) await init()

    const token: string = await window.grecaptcha.enterprise.execute(config.public.recaptchaSiteKey, { action })

    if (callback) return callback(token)
    return token
  }

  init()

  return {
    init,
    execute,
  }
}
